import React from 'react';
import { string, node, bool, func } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const CustomSelectbox = ({ id, children, checked, onChange, dataTestId }) => (
  <label className="pbb-filter-item filter-item" htmlFor={id}>
    {children}
    <input type="checkbox" id={id} onChange={onChange} checked={checked} data-testid={dataTestId} />
    <span className="checkmark" />
  </label>
);

CustomSelectbox.propTypes = {
  id: string.isRequired,
  children: node.isRequired,
  checked: bool.isRequired,
  onChange: func.isRequired,
  dataTestId: string,
};
