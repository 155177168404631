import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOf, bool, shape, string, oneOf } from 'prop-types';
import { MenuItem } from '@pelckmans/business-components/components/action-menu';
import { useTranslation } from 'react-i18next';
import ShareIcon from '@pelckmans/business-components/icons/Share';
import InfoIcon from '@pelckmans/business-components/icons/Info';
import { checkIsExercise } from '@pelckmans/business-components/util/medialink';
import FavoriteButton from '../../../../../buttons/FavoriteButton';
import { toggleMedialinkFavorite } from '../../../../../../../../actions/medialinks';
import { openMaterialAssignmentModal, openMedialink, openMedialinkOptions, openMinisite } from '../../../../../../../../actions/dialog';
import MaterialInfo from '../MaterialInfo';
import { ANALYTICS_EVENT_SUBLOCATIONS } from '../../../../../../../../enums/analytics';
import DrawerOptionsMenu from '../../../DrawerOptionsMenu';
import OptionsButton from '../../../../../buttons/OptionsButton';
import { getTeacherFeaturesEnabledFor } from '../../../../../../../../selectors/digibooks';
import UnlicensedMedialinkItem from './UnlicensedMedialinkItem';
import useCloseDrawerOnMobile from '../../../hooks/useCloseDrawerOnMobile';
import { trackMedialinkEvent, validateAndGetLinkFromMedialink } from '../../../../../../../../utils/medialink';

const LicensedMedialinkItem = ({ medialink, subLocationId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const teacherFeaturesEnabled = useSelector(getTeacherFeaturesEnabledFor);

  const openMedialinkAndCloseDrawer = useCloseDrawerOnMobile(e => {
    e.preventDefault();

    const currentAssignment = medialink.assignments?.length ? medialink.assignments.find(a => a.current) : undefined;
    const isMaterialAssignment = currentAssignment && !checkIsExercise(medialink.kind);

    if (isMaterialAssignment) return dispatch(openMaterialAssignmentModal(medialink, 'medialink', currentAssignment, currentAssignment.user));

    if (medialink.miniSite) {
      return dispatch(openMinisite(medialink, subLocationId));
    }

    return dispatch(openMedialink(medialink, subLocationId));
  });

  const toggleFavorite = e => {
    e.preventDefault();
    dispatch(toggleMedialinkFavorite(medialink));
  };

  const openOptionsModalAndCloseDrawer = useCloseDrawerOnMobile((ml, activeTab) => dispatch(openMedialinkOptions(ml, activeTab)));

  const logEventAndCloseDrawer = useCloseDrawerOnMobile(() => trackMedialinkEvent(medialink.id, subLocationId));

  const urlToBeOpenedInNewTab = validateAndGetLinkFromMedialink(medialink);

  return (
    <li className="pbb-list__item" data-testid="medialink" data-id={medialink.id}>
      <div className="pbb-list__link-wrap">
        {urlToBeOpenedInNewTab ? (
          <a
            className="pbb-list__link pbb-flex-wrap"
            href={urlToBeOpenedInNewTab}
            target="_blank"
            rel="noopener noreferrer"
            title={medialink.name}
            onClick={logEventAndCloseDrawer}
          >
            <MaterialInfo material={medialink} materialType="medialink" />
          </a>
        ) : (
          <a className="pbb-list__link pbb-flex-wrap" href="/#" title={medialink.name} onClick={openMedialinkAndCloseDrawer}>
            <MaterialInfo material={medialink} materialType="medialink" />
          </a>
        )}
        <div className="pbb-list__actions">
          <FavoriteButton onClick={toggleFavorite} isActive={medialink.isFavorite} className="pbb-list__action" />
          {teacherFeaturesEnabled ? (
            <DrawerOptionsMenu>
              <MenuItem onClick={() => openOptionsModalAndCloseDrawer(medialink, 'sharing')}>
                <ShareIcon />
                <span>{t('tabs.sharing')}</span>
              </MenuItem>
              <MenuItem onClick={() => openOptionsModalAndCloseDrawer(medialink, 'info')}>
                <InfoIcon />
                <span>{t('tabs.info.title')}</span>
              </MenuItem>
            </DrawerOptionsMenu>
          ) : (
            <OptionsButton onClick={() => openOptionsModalAndCloseDrawer(medialink, 'info')} className="pbb-list__action" />
          )}
        </div>
      </div>
    </li>
  );
};

const checkMedialinkIsNotLicensed = medialink =>
  !medialink.file &&
  !medialink.externalMedia &&
  !medialink.edumaticExercise &&
  !medialink.eduhintExercise &&
  !medialink.assessmentQExercise &&
  !medialink.miniDialog &&
  !medialink.wordList &&
  !medialink.miniSite;

const MedialinkItem = ({ medialink, subLocationId }) => {
  const isNotLicensed = checkMedialinkIsNotLicensed(medialink);
  if (isNotLicensed) {
    return <UnlicensedMedialinkItem medialink={medialink} />;
  }
  return <LicensedMedialinkItem medialink={medialink} subLocationId={subLocationId} />;
};

MedialinkItem.propTypes = {
  medialink: shape({
    id: string.isRequired,
    name: string.isRequired,
    moduleId: string.isRequired,
    kind: string.isRequired,
    shares: arrayOf(
      shape({
        id: string.isRequired,
        label: string,
      }),
    ),
    assignments: arrayOf(
      shape({
        id: string,
        from: string,
        to: string,
        repeatable: bool,
      }),
    ),
  }).isRequired,
  subLocationId: oneOf(Object.values(ANALYTICS_EVENT_SUBLOCATIONS)).isRequired,
};

LicensedMedialinkItem.propTypes = MedialinkItem.propTypes;

export default MedialinkItem;
