const MedialinkKind = Object.freeze({
  EXTERNAL_MEDIA: 'external-media',
  EXERCISE_EDUMATIC: 'exercise-edumatic',
  EXERCISE_EDUHINT: 'exercise-eduhint',
  EXERCISE_ASSESSMENTQ: 'exercise-assessmentq',
  FILE: 'file',
  MINI_DIALOG: 'mini-dialog',
  WORD_LIST: 'word-list',
});

export default MedialinkKind;
