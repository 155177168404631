import React, { useRef, useEffect, useContext, useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { shape, string, number, object, bool, func, array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextAnnotations } from '@pelckmans/business-components/components/text-annotations';
import FabricService from '../../services/fabric-service';
import { getCurrentTool, getTools } from '../../../../selectors/tools';
import { setCurrentTool } from '../../../../actions/tools';
import { disableZoomToFitMode, setZoomLevel } from '../../../../actions/navigation';
import { getZoomLevel, getZoomToFitMode } from '../../../../selectors/navigation';
import { UserSettingsContext } from '../../context/user-settings-context';
import Tools from '../../../../enums/tools';
import { setPlayerMode } from '../../../../actions/playerMode';
import playerMode from '../../../../enums/playerMode';
import { useWhitepageAnnotations } from './hooks/use-whitepage-annotations';
import MarkingLayer from './components/marking-layer';
import { WHITEPAGE_HEIGHT, WHITEPAGE_WIDTH } from './constants';
import { LANGUAGE } from '../../../../constants/constants';
import CustomColorContext from '../../../../contexts/CustomColorContext';
import useTextAnnotationTranslations from '../../../../hooks/useTextAnnotationTranslations';
import AnchorPosition from '../../../../enums/anchorposition';
import { MobileSizeContext } from '../../context/MobileSizeContext';
import PrintPages from '../../../../components/print-pages';
import api from '../../../../services/api';

const pageDimensions = {
  width: 296,
  height: 210,
};
/*
  This function is used to filter out annotations that are on top and below the whitepage.
  Annotations that are on top of the whitepage will shift to the bottom of the whitepage when printed (library issue).
  Annotations that are below the whitepage would add another page to the print.
*/
function getAnnotationsToPrint(annotations) {
  return annotations.filter(x => x.top < WHITEPAGE_HEIGHT && x.top > 0);
}

function useCanvasZoom(fabricService, zoomLevel, zoomToFitMode, canvasWidth, canvasHeight) {
  useEffect(() => {
    if (zoomToFitMode) fabricService.current.scaleCanvasToFit(false, fabricService.current.zoomLevel);
  }, [fabricService, zoomToFitMode]);

  useEffect(() => {
    fabricService.current.handleCanvasResize({ width: canvasWidth, height: canvasHeight });
  }, [fabricService, canvasWidth, canvasHeight]);

  useEffect(() => {
    fabricService.current.setZoom(zoomLevel);
  }, [fabricService, zoomLevel]);
}

function useCanvasSidebarPositioning(fabricService, openDrawer, zoomLevel, zoomToFitMode, updateViewPort) {
  useEffect(() => {
    fabricService.current.setDrawerOpenSide(openDrawer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (zoomLevel === 1 && zoomToFitMode)
      fabricService.current.shiftViewportForDrawer(openDrawer, () => {
        updateViewPort();
        fabricService.current.fabricCanvas.requestRenderAll();
      });
  }, [fabricService, openDrawer, zoomLevel, zoomToFitMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fabricService.current.setDrawerOpenSide(openDrawer);
  }, [fabricService, openDrawer]);
}

function Whitepage(props) {
  const { whitepage, currentTool, dimensions, zoomToFitMode, zoomLevel, paper, initialDrawings, initialAnnotations, paperTypes, dispatch } = props;
  const [printData, setPrintData] = useState();
  const canvasWrapperRef = useRef(null);

  const { customColors, saveCustomColor } = useContext(CustomColorContext);

  const { sidebarAnchor, isSidebarOpen, setSidebarOpened } = useContext(UserSettingsContext);
  const fabricService = useRef(null);

  const [viewportTransform, setViewportTransform] = useState();

  const { annotations, addAnnotation, editAnnotation, selectedAnnotation, setSelectedAnnotation, removeAnnotation } = useWhitepageAnnotations(whitepage, initialAnnotations);

  const translations = useTextAnnotationTranslations();

  useEffect(() => {
    async function printWhitePageEvent() {
      const { data } = await api.get(whitepage.url);
      setPrintData({
        imageUrls: { 0: paperTypes[whitepage.paperType] },
        markings: { 0: data },
        textAnnotations: { 0: getAnnotationsToPrint(annotations) },
      });
    }

    document.addEventListener('print-whitepage', printWhitePageEvent);

    return () => {
      document.removeEventListener('print-whitepage', printWhitePageEvent);
    };
  }, [annotations, paperTypes, whitepage.paperType, whitepage.url]);
  const [t] = useTranslation();

  const zoomSelectionHandler = useCallback(
    nextZoom => {
      dispatch(setZoomLevel(nextZoom));
      dispatch(setCurrentTool(Tools.POINTER));
    },
    [dispatch],
  );

  const onAfterPrinting = () => {
    setPrintData(null);
  };

  const disableZoomToFit = useCallback(() => dispatch(disableZoomToFitMode()), [dispatch]);

  const [responsiveSidebarAnchor, updateSidebarAnchor] = useState(sidebarAnchor);
  const { isMobileHeight } = useContext(MobileSizeContext);

  useEffect(() => {
    updateSidebarAnchor(sidebarAnchor);

    if (isMobileHeight) {
      updateSidebarAnchor(AnchorPosition.TOP);
      setSidebarOpened(false);
    }
  }, [isMobileHeight, sidebarAnchor, setSidebarOpened]);

  const openDrawer = isSidebarOpen ? responsiveSidebarAnchor : undefined;

  useEffect(() => {
    fabricService.current = new FabricService('white-page-canvas', undefined, undefined, playerMode.WHITEPAGE);
    fabricService.current.initialize(WHITEPAGE_HEIGHT, WHITEPAGE_WIDTH);

    return () => {
      fabricService.current.dispose();
    };
  }, []);

  const updateViewport = useCallback(() => {
    const fabricVpt = fabricService.current.getViewportTransform();

    if (!viewportTransform || viewportTransform.some((item, i) => item !== fabricVpt[i])) {
      setViewportTransform([...fabricVpt]);
    }
  }, [viewportTransform]);

  useEffect(() => {
    fabricService.current.addDragListeners(
      {
        [Tools.ZOOM_SELECT]: zoomSelectionHandler,
      },
      updateViewport,
      () => zoomToFitMode && disableZoomToFit(),
    );

    return () => {
      fabricService.current.removeDragListeners();
    };
  }, [zoomSelectionHandler, disableZoomToFit, zoomToFitMode, updateViewport]);

  useEffect(() => {
    function pinchHandler(zoomFactor) {
      dispatch(setZoomLevel(zoomFactor));
    }
    fabricService.current.addPinchListeners(pinchHandler);
  }, [dispatch]);

  useEffect(() => {
    fabricService.current.renderBookPage(paper, 'left', 'left');
  }, [paper]);

  useEffect(() => {
    fabricService.current.addWhitepageHeader(whitepage.title, () => dispatch(setPlayerMode(playerMode.BOOK)), t('whitepages.modal.buttons.close'));
  }, [dispatch, whitepage.title, t]);

  useCanvasZoom(fabricService, zoomLevel, zoomToFitMode, dimensions.width, dimensions.height);

  useCanvasSidebarPositioning(fabricService, openDrawer, zoomLevel, zoomToFitMode, updateViewport);

  useEffect(() => {
    if (fabricService.current) {
      fabricService.current.renderAll();
      updateViewport();
    }
  });

  const layers = useMemo(
    () =>
      viewportTransform
        ? [
            <TextAnnotations
              key={`text-annotations-${whitepage.id}`}
              annotations={annotations}
              addAnnotation={addAnnotation}
              editAnnotation={editAnnotation}
              selectedAnnotation={selectedAnnotation}
              setSelectedAnnotationId={setSelectedAnnotation}
              removeAnnotation={removeAnnotation}
              viewportTransform={viewportTransform}
              pageHeight={paper.height}
              pageWidth={paper.width}
              isSinglePage
              isRightPage={false}
              isStandalonePage
              isSolutionsPageVisible={false}
              sidebarAnchor={sidebarAnchor}
              customColors={customColors}
              saveCustomColor={saveCustomColor}
              lang={LANGUAGE}
              isInteractive={currentTool === Tools.TEXT_ANNOTATION}
              translations={translations}
              canvasWidth={canvasWrapperRef.current.clientWidth}
            />,
            <MarkingLayer key={`markings-${whitepage.id}`} initialDrawings={initialDrawings} viewportTransform={viewportTransform} whitepage={whitepage} paper={paper} />,
          ]
        : [],
    [
      addAnnotation,
      annotations,
      currentTool,
      customColors,
      editAnnotation,
      initialDrawings,
      paper,
      removeAnnotation,
      saveCustomColor,
      selectedAnnotation,
      setSelectedAnnotation,
      sidebarAnchor,
      translations,
      viewportTransform,
      whitepage,
    ],
  );

  return (
    <div className="canvas-wrapper" ref={canvasWrapperRef}>
      <div>
        <canvas id="white-page-canvas" data-testid="white-page-canvas" />
        {currentTool === Tools.TEXT_ANNOTATION ? layers.reverse() : layers}
      </div>
      <PrintPages printData={printData} initialPages={[0]} pageDimensions={pageDimensions} onAfterPrint={onAfterPrinting} />
    </div>
  );
}

const mapStateToProps = state => ({
  currentTool: getCurrentTool(state),
  tools: getTools(state),
  zoomToFitMode: getZoomToFitMode(state),
  zoomLevel: getZoomLevel(state),
});

Whitepage.propTypes = {
  whitepage: shape({
    id: string.isRequired,
    url: string.isRequired,
    annotationsUrl: string.isRequired,
    title: string.isRequired,
  }).isRequired,
  currentTool: string.isRequired,
  dimensions: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  zoomToFitMode: bool.isRequired,
  zoomLevel: number.isRequired,
  dispatch: func.isRequired,
  paper: object.isRequired,
  initialDrawings: array,
  initialAnnotations: array,
  paperTypes: shape({
    blank: string.isRequired,
    raster: string.isRequired,
    media: string.isRequired,
    lines: string.isRequired,
  }),
};

export const ConnectedWhitepage = connect(mapStateToProps)(Whitepage);

export default ConnectedWhitepage;
