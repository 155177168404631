import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { func, string, shape, bool, number } from 'prop-types';
import { BearerTokenGetterContext } from '@pelckmans/business-components/context/BearerTokenGetterContext';
import { MiniDialogModalTranslationsContext } from '@pelckmans/business-components/context/MiniDialogModalTranslationsContext';
import { MiniDialogModal } from '@pelckmans/business-components/components/mini-dialog-modal';
import { ErrorHandlerContext } from '@pelckmans/business-components/context/ErrorHandlerContext';
import { useTranslation } from 'react-i18next';

import MedialinkKind from '../../../../enums/medialinkKind';
import ExternalMediaTypes from '../../../../enums/externalmediatype';

import FullScreenMediaDialog from './mediaDialogs/fullScreen';
import MediaLinkBody from './mediaDialogs/mediaLinkBody';
import Modal from './mediaDialogs/modal';
import RndDialog from './mediaDialogs/rnd';

import { getTOCNodeById } from '../../../../selectors/toc';
import { getModuleById } from '../../../../selectors/module';

import { closeDialog, moveMediaDialogToFront } from '../../../../actions/dialog';
import { errorOccurred } from '../../../../actions/error';

import { getFileType } from '../../../../utils/filetype';
import * as Url from '../../../../utils/url';

import getAccessToken from '../../../../services/utils/bearer-token';

import { PORTAAL_API_URL, PORTAAL_URL, STUDIO_GROUP } from '../../../../constants/constants';
import MedialinkInfo from './options/info';
import DocumentDialog from './DocumentDialog';
import ExerciseFlowContainer from '../exercises/ExerciseFlowContainer';
import WebsiteModal from './mediaDialogs/website-modal';
import H5PModal from './mediaDialogs/h5p-modal';
import Storage from '../../../../services/storage';
import StorageKey from '../../../../services/storage-keys';

const buildFAQURL = lang => Url.join(PORTAAL_URL, lang === 'fr' ? 'support' : 'info-hulp');

function MediaDialog(props) {
  const { medialink, isInFront, height, width, x, y, setDimensions, setPosition, superModuleId, close, moveDialogToFront, subLocationId } = props;
  const closeHandler = useCallback(
    event => {
      if (event) event.preventDefault();
      setDimensions(medialink.id, 0, 0);
      close(medialink.id);
    },
    [setDimensions, close, medialink],
  );

  const [bearerToken, setBearerToken] = useState(null);
  const moveToFront = useCallback(() => {
    moveDialogToFront(medialink.id);
  }, [moveDialogToFront, medialink]);

  const node = useSelector(state => getTOCNodeById(state)(medialink.hierarchy[medialink.hierarchy.length - 1]));
  const superModule = useSelector(state => getModuleById(state, superModuleId));
  const moduleForMedialink = useSelector(state => getModuleById(state, medialink.moduleId));
  const dispatch = useDispatch();
  const getBearerToken = useCallback(() => getAccessToken(dispatch), [dispatch]);
  const errorHandler = useCallback(() => dispatch(errorOccurred({ status: 500, originalError: 'Error in Mini Dialog Modal component' })), [dispatch]);
  const {
    t,
    i18n: { lang },
  } = useTranslation();

  useEffect(() => {
    const fetchBearerToken = async () => setBearerToken(await getBearerToken());

    fetchBearerToken();
  }, [getBearerToken]);

  const miniDialogTranslations = useMemo(
    () => ({
      closeDialog: t('actions.close'),
      generalNode: t('drawer.toc.general'),
      playDialog: t('businessComponents.miniDialog.playDialog'),
      stopDialog: t('businessComponents.miniDialog.stopDialog'),
      example: t('businessComponents.miniDialog.example'),
      ownVoice: t('businessComponents.miniDialog.ownVoice'),
      optionsTextAndSound: t('businessComponents.miniDialog.optionsTextAndSound'),
      optionsKeyWords: t('businessComponents.miniDialog.optionsKeyWords'),
      playTitle: t('businessComponents.miniDialog.playTitle'),
      pauseTitle: t('businessComponents.miniDialog.pauseTitle'),
      recordTitle: t('businessComponents.miniDialog.recordTitle'),
      stopRecordTitle: t('businessComponents.miniDialog.stopRecordTitle'),
      playRecordingTitle: t('businessComponents.miniDialog.playRecordingTitle'),
      pauseRecordingTitle: t('businessComponents.miniDialog.pauseRecordingTitle'),
      hideTextTitle: t('businessComponents.miniDialog.hideTextTitle'),
      showTextTitle: t('businessComponents.miniDialog.showTextTitle'),
      hideKeywordsTitle: t('businessComponents.miniDialog.hideKeywordsTitle'),
      showKeywordsTitle: t('businessComponents.miniDialog.showKeywordsTitle'),
      muteTitle: t('businessComponents.miniDialog.muteTitle'),
      unmuteTitle: t('businessComponents.miniDialog.unmuteTitle'),
      errors: {
        browserSupport: t('businessComponents.miniDialog.errors.browserSupport', { faqURL: buildFAQURL(lang) }),
        accessDenied: t('businessComponents.miniDialog.errors.accessDenied', { faqURL: buildFAQURL(lang) }),
        noMicrophone: t('businessComponents.miniDialog.errors.noMicrophone'),
      },
    }),
    [t, lang],
  );

  switch (medialink.kind) {
    case MedialinkKind.EXTERNAL_MEDIA: {
      if (medialink.externalMedia.type === ExternalMediaTypes.YOUTUBE) {
        return (
          <RndDialog
            title={medialink.name}
            icon={medialink.icon}
            close={closeHandler}
            moveDialogToFront={moveToFront}
            isInFront={isInFront}
            setDimensions={setDimensions}
            setPosition={setPosition}
            height={height}
            width={width}
            hideDownload
            id={medialink.id}
            x={x}
            y={y}
          >
            <MediaLinkBody medialink={medialink} />
          </RndDialog>
        );
      }
      if (medialink.externalMedia.type === ExternalMediaTypes.WEBLINK) {
        const {
          name,
          icon,
          externalMedia: { target, href, width: medialinkWidth, height: medialinkHeight, reload },
        } = medialink;

        if (target !== 'modal') throw new Error(`Unsupported WebLink target ${target}!`);

        if (href.includes('h5p.org') || href.includes('h5p.com')) {
          return <H5PModal medialink={medialink} onClose={closeHandler} moveToFront={moveToFront} isInFront={isInFront} />;
        }

        return <WebsiteModal onClose={closeHandler} width={medialinkWidth} height={medialinkHeight} name={name} url={href} iconClassName={icon} reload={reload} />;
      }
      if (medialink.externalMedia.type === ExternalMediaTypes.H5P) {
        return <H5PModal medialink={medialink} onClose={closeHandler} moveToFront={moveToFront} isInFront={isInFront} />;
      }
      if (medialink.externalMedia.type === ExternalMediaTypes.GEOGEBRA) {
        if (medialink.externalMedia.width && medialink.externalMedia.height) {
          return (
            <Modal
              width={medialink.externalMedia.width}
              height={medialink.externalMedia.height}
              title={medialink.name}
              icon={medialink.icon}
              close={closeHandler}
              moveDialogToFront={moveToFront}
              isInFront={isInFront}
              className="pbb-modal--geogebra"
            >
              <MediaLinkBody medialink={medialink} />
            </Modal>
          );
        }
        return (
          <FullScreenMediaDialog title={medialink.name} close={closeHandler} moveDialogToFront={moveToFront} icon={medialink.icon}>
            <MediaLinkBody medialink={medialink} type={medialink.file && getFileType(medialink.file.s3file.mimeType)} />
          </FullScreenMediaDialog>
        );
      }
      return null;
    }
    case MedialinkKind.FILE: {
      const type = getFileType(medialink.file.s3file.mimeType);
      const canResize = type !== 'audio';
      const downloadlink = medialink.file.forceDownload
        ? `${PORTAAL_API_URL}/api/s3-files/${medialink.file.s3file.id}/link?access_token=${bearerToken}&attachment=${medialink.name}`
        : null;
      const rndTypes = ['audio', 'video', 'pdf'];

      if (type === 'image') {
        return (
          <FullScreenMediaDialog title={medialink.name} close={closeHandler} moveDialogToFront={moveToFront} withPadding icon={medialink.icon}>
            <MedialinkInfo medialink={medialink} aside downloadLink={downloadlink} />
            <div className="pbb-modal__object">
              <MediaLinkBody medialink={medialink} type={type} />
            </div>
          </FullScreenMediaDialog>
        );
      }

      if (rndTypes.includes(type) || medialink.imagePreview || medialink.file.s3file.preview) {
        return (
          <RndDialog
            title={medialink.name}
            icon={medialink.icon}
            close={closeHandler}
            moveDialogToFront={moveToFront}
            isInFront={isInFront}
            setDimensions={setDimensions}
            setPosition={setPosition}
            height={height}
            width={width}
            x={x}
            y={y}
            resizeEnabled={canResize}
            id={medialink.id}
            downloadLink={downloadlink}
            isImagePreview={medialink.imagePreview}
            linkedMediaId={medialink.file.linkedMedia}
          >
            <MediaLinkBody medialink={medialink} type={type} />
          </RndDialog>
        );
      }

      return <DocumentDialog medialink={medialink} close={closeHandler} downloadLink={downloadlink} superModuleId={superModuleId} />;
    }
    case MedialinkKind.MINI_DIALOG: {
      return (
        <ErrorHandlerContext.Provider value={errorHandler}>
          <BearerTokenGetterContext.Provider value={getBearerToken}>
            <MiniDialogModalTranslationsContext.Provider value={miniDialogTranslations}>
              <MiniDialogModal
                hasCompactHeader
                mediaLink={medialink}
                onClose={closeHandler}
                superModuleHasExerciseEntrance={Boolean((superModule || {}).hasExerciseEntrance)}
                urlPrefix={PORTAAL_API_URL}
                studioGroup={STUDIO_GROUP}
              />
            </MiniDialogModalTranslationsContext.Provider>
          </BearerTokenGetterContext.Provider>
        </ErrorHandlerContext.Provider>
      );
    }

    /**
        @deprecated
        This code is no longer called since we open wordlists in a new tab. See actions/dialogs.js
        Keeping it in case we can open wordlists in a modal again after the migration is complete.
    */
    case MedialinkKind.WORD_LIST: {
      if (!PORTAAL_URL) return null;

      const {
        name,
        icon,
        wordList: { id: wordListId },
      } = medialink;

      const accessToken = Storage.get(StorageKey.ACCESS_TOKEN);
      const refreshToken = 'no-refresh-token-set';
      const loginUrl = `${PORTAAL_URL}auth-callback?access_token=${accessToken}&refresh_token=${refreshToken}`;
      const wordlistUrl = encodeURIComponent(`/word-list/${moduleForMedialink.slug}/${wordListId}`);

      const src = `${loginUrl}&returnUrl=${wordlistUrl}`;
      return (
        <FullScreenMediaDialog title={name} close={closeHandler} icon={icon} moveDialogToFront={moveToFront}>
          <div className="pbb-modal__iframe-wrapper">
            <iframe className="iframe--fill iframe--no-border" src={src} title={name} />
          </div>
        </FullScreenMediaDialog>
      );
    }
    case MedialinkKind.EXERCISE_ASSESSMENTQ:
    case MedialinkKind.EXERCISE_EDUHINT:
    case MedialinkKind.EXERCISE_EDUMATIC: {
      // mediaLink mapper reduces the hierarchy to an array of node-ids
      // => we need to rebuild the hierarchy from the node-id we retrieve from the state
      const enrichedMediaLink = {
        ...medialink,
        hierarchy: [
          {
            id: node.id,
            title: node.title,
            prefix: node.prefix,
            displayPrefix: node.displayPrefix,
          },
        ],
      };

      // we need to now the location of the component that triggered this the action that added this dialog (the openMedialink action)

      const currentAssignment = medialink.assignments?.find(assignment => assignment.current);

      return <ExerciseFlowContainer medialink={enrichedMediaLink} assignmentId={currentAssignment?.id} superModuleId={superModuleId} subLocationId={subLocationId} />;
    }

    default:
      return null;
  }
}

MediaDialog.propTypes = {
  medialink: shape({
    id: string.isRequired,
    kind: string.isRequired,
    moduleId: string.isRequired,
    file: shape({
      forceDownload: bool,
      s3file: shape({
        id: string,
        mimeType: string,
      }),
    }),
    externalMedia: shape({
      href: string,
      type: string,
    }),
    miniDialog: shape({
      id: string.isRequired,
    }),
    wordList: shape({
      id: string.isRequired,
    }),
  }).isRequired,
  close: func.isRequired,
  moveDialogToFront: func.isRequired,
  isInFront: bool.isRequired,
  superModuleId: string,
  // eslint-disable-next-line react/no-unused-prop-types
  medialinkId: string.isRequired, // ownProps
  height: number,
  width: number,
  x: number,
  y: number,
  setDimensions: func.isRequired,
  setPosition: func.isRequired,
  subLocationId: string,
};

const mapDispatchToProps = {
  close: closeDialog,
  moveDialogToFront: moveMediaDialogToFront,
};

export default connect(undefined, mapDispatchToProps)(MediaDialog);
