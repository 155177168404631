import { ANALYTICS_EVENT_TYPES } from '@pelckmans/business-components/hooks/constants/Analytics';
import { postEvent } from '../api/analyticsApi';

export const trackMedialinkEvent = async (medialinkId, subLocationId) => {
  await postEvent({
    eventType: ANALYTICS_EVENT_TYPES.MEDIALINK_ACCESSED,
    objectId: medialinkId,
    subLocationId,
  });
};

const isMedialinkStudentAssignment = medialink => medialink.assignments.some(assignment => 'current' in assignment);

function getLinkFromMedialink(medialink) {
  if (medialink.kind === 'mini-site' && medialink.miniSite.target === 'new-window') {
    return medialink.miniSite.href;
  }

  if (medialink.externalMedia?.type === 'weblink' && medialink.externalMedia?.target === 'new-window') {
    return medialink.externalMedia.href;
  }

  return undefined;
}

export function validateAndGetLinkFromMedialink(medialink) {
  if (isMedialinkStudentAssignment(medialink)) return undefined;

  return getLinkFromMedialink(medialink);
}

export function isShowScore(medialink) {
  return Boolean(medialink.eduhintExercise?.showScore || medialink.edumaticExercise?.showScore || medialink.assessmentQExercise?.showScore);
}
