import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PrintPage from './print-page';

import notosansMono from '../../assets/fonts/noto-sans-mono.ttf';
import playwrite from '../../assets/fonts/playwrite.ttf';
import openSans from '../../assets/fonts/open-sans.ttf';
import printStyles from '../../scss/print/print-pages.scss?raw';

const SAFARI_MARGIN = 4;

function PrintPages({ printData, initialPages, onAfterPrint, pageDimensions, includeWatermark = false }) {
  const iframeRef = useRef(null);
  const isSafari = window.safari !== undefined || /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const [isIframeRendered, setIsIframeRendered] = useState(false);

  const [rendered, setRendered] = useState(
    initialPages.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: false,
      }),
      {},
    ),
  );

  useEffect(() => {
    const handleWindowFocus = () => {
      onAfterPrint();
      window.removeEventListener(isSafari ? 'afterprint' : 'focus', handleWindowFocus);
    };
    window.addEventListener(isSafari ? 'afterprint' : 'focus', handleWindowFocus);
  }, [isSafari, onAfterPrint]);

  useEffect(() => {
    if (Object.values(rendered).every(x => x)) {
      iframeRef.current.contentWindow.focus();
      iframeRef.current.contentWindow.print();
    }
  }, [rendered]);

  useEffect(() => {
    setIsIframeRendered(true);
  }, []);

  return createPortal(
    <iframe style={{ visibility: 'hidden', width: '1px', height: '1px' }} data-testid="iframe" ref={iframeRef} title="print-pages">
      {isIframeRendered &&
        createPortal(
          <>
            <style>
              {`
                  @font-face {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-stretch: 100%;
                    font-display: swap;
                    src: url(${openSans}) format('truetype');
                  }
                  @font-face {
                    font-family: 'NotoSansMono';
                    font-style: normal;
                    font-weight: 400;
                    font-stretch: 100%;
                    font-display: swap;
                    src: url(${notosansMono}) format('truetype');
                  }
                  @font-face {
                    font-family: 'Playwrite';
                    font-style: normal;
                    font-weight: 400;
                    font-stretch: 100%;
                    font-display: swap;
                    src: url(${playwrite}) format('truetype');
                  }
                  `}
            </style>
            <style>
              {`
                .page {
                  max-height: ${isSafari ? pageDimensions.height - SAFARI_MARGIN : pageDimensions.height}mm;
                  min-height: ${isSafari ? pageDimensions.height - SAFARI_MARGIN : pageDimensions.height}mm;
                  width: ${pageDimensions.width}mm;
                }
                .subpage {
                  max-height: ${isSafari ? pageDimensions.height - SAFARI_MARGIN : pageDimensions.height}mm;
                  height: ${isSafari ? pageDimensions.height - SAFARI_MARGIN : pageDimensions.height}mm;
                }
                @page {
                  size: ${pageDimensions.width}mm ${pageDimensions.height}mm;
                  ${
                    isSafari
                      ? ''
                      : `
                    margin:0;
                    padding:0;
                    `
                  }
                }

                @media print {
                  html, body {
                  height: ${isSafari ? pageDimensions.height - SAFARI_MARGIN : pageDimensions.height}mm;
                  width: ${pageDimensions.width}mm;
                }
              `}
            </style>
            <style>{printStyles}</style>
            {printData &&
              Object.entries(printData.imageUrls).map(([page, url]) => (
                <PrintPage
                  key={page}
                  onRenderDone={() => {
                    setRendered(prev => ({
                      ...prev,
                      [page]: true,
                    }));
                  }}
                  imageSrc={url}
                  pageNumber={Number(page)}
                  markings={printData.markings?.[page]}
                  annotations={printData.textAnnotations?.[page]}
                  includeWatermark={includeWatermark}
                />
              ))}
          </>,
          iframeRef.current.contentWindow.document.body,
        )}
    </iframe>,
    document.body,
  );
}

PrintPages.propTypes = {
  printData: PropTypes.shape({
    imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
    markings: PropTypes.arrayOf(PropTypes.object).isRequired,
    textAnnotations: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
  initialPages: PropTypes.arrayOf(PropTypes.number).isRequired,
  onAfterPrint: PropTypes.func.isRequired,
  pageDimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  includeWatermark: PropTypes.bool,
};

export default PrintPages;
