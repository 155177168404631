import { MenuItem } from '@pelckmans/business-components/components/action-menu';
import BookmarkOutlineIcon from '@pelckmans/business-components/icons/BookmarkOutline';
import LightBulbIcon from '@pelckmans/business-components/icons/LightBulb';
import PrintIcon from '@pelckmans/business-components/icons/Print';
import classNames from 'classnames';
import { arrayOf, bool, func, node, number, shape, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openTocNodeOptionsModal } from '../../../../../../actions/dialog';
import { EMPTY_ARRAY } from '../../../../../../utils/stable-default-props';
import DrawerOptionsMenu from '../DrawerOptionsMenu';
import useCloseDrawerOnMobile from '../hooks/useCloseDrawerOnMobile';

const DEFAULT_NODE = { id: '', displayPrefix: false, prefix: '', title: '' };

const Anchor = ({ tocNode, href, onNodeClicked, onLinkClicked, children }) => {
  if (tocNode && tocNode.fromPage) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className="pbb-toc__item-link"
        href="#"
        onClick={e => {
          e.preventDefault();
          onNodeClicked(tocNode);
        }}
      >
        {children}
      </a>
    );
  }
  return (
    <a className="pbb-toc__item-link" href={href} onClick={onLinkClicked} title={tocNode.title} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

Anchor.propTypes = {
  tocNode: shape({ id: string, displayPrefix: bool, prefix: string, title: string }),
  href: string,
  onNodeClicked: func.isRequired,
  onLinkClicked: func.isRequired,
  children: node.isRequired,
};

const TocItem = ({
  onNodeClicked,
  onLinkClicked,
  buildPUrl,
  tocNode = DEFAULT_NODE,
  expanded = false,
  active = false,
  subnodes = EMPTY_ARRAY,
  level = 1,
  showOptions = false,
  hasSharedSolutions = false,
}) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const openOptionsModalAndCloseDrawer = useCloseDrawerOnMobile(activeTab => dispatch(openTocNodeOptionsModal(tocNode, activeTab)));

  return (
    <li
      className={classNames(`pbb-toc__item--level-${level}`, 'pbb-toc__item', {
        'pbb-expanded': expanded && level === 1,
        'pbb-toc__item--sub': level > 1,
        'pbb-toc__item--active': active,
      })}
    >
      <span className="pbb-toc__item-wrapper">
        <Anchor onNodeClicked={onNodeClicked} onLinkClicked={onLinkClicked} tocNode={tocNode} href={buildPUrl(tocNode)}>
          <span className="pbb-toc__item-bookmark" data-testid="bookmark-icon">
            <BookmarkOutlineIcon />
          </span>

          <div className="pbb-toc__item-title-wrapper">
            {tocNode.displayPrefix && (
              <div className="pbb-toc__item-subtitle" data-testid="node-prefix">
                {tocNode.prefix}
              </div>
            )}
            <div className="pbb-toc__item-title">{tocNode.title}</div>
          </div>
        </Anchor>
        {hasSharedSolutions &&
          (showOptions ? (
            <button
              type="button"
              className="pbb-toc__option pbb-shared-solution-indicator"
              onClick={() => openOptionsModalAndCloseDrawer('sharing_solutions')}
              title={t('sharing.solutions.tocIndicator.tooltip')}
              disabled={!hasSharedSolutions}
            >
              <LightBulbIcon />
            </button>
          ) : (
            <div className="pbb-toc__option pbb-shared-solution-indicator" title={t('sharing.solutions.tocIndicator.tooltip')}>
              <LightBulbIcon />
            </div>
          ))}
        <DrawerOptionsMenu>
          {showOptions && (
            <MenuItem onClick={() => openOptionsModalAndCloseDrawer('sharing_solutions')}>
              <LightBulbIcon />
              <span>{t('tabs.sharing_solutions')}</span>
            </MenuItem>
          )}
          <MenuItem onClick={() => openOptionsModalAndCloseDrawer('print')}>
            <PrintIcon />
            <span>{t('tabs.print')}</span>
          </MenuItem>
        </DrawerOptionsMenu>
      </span>
      {subnodes && expanded && <ul className={`pbb-toc__list-level-${level + 1}`}>{subnodes}</ul>}
    </li>
  );
};

TocItem.propTypes = {
  onNodeClicked: func.isRequired,
  onLinkClicked: func.isRequired,
  buildPUrl: func.isRequired,
  tocNode: shape({ id: string, displayPrefix: bool, prefix: string, title: string }),
  expanded: bool,
  active: bool,
  subnodes: arrayOf(shape({ displayPrefix: bool, prefix: string, title: string })),
  level: number,
  showOptions: bool,
  hasSharedSolutions: bool,
};

export default TocItem;
