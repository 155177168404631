import { checkIsExercise } from '@pelckmans/business-components/util/medialink';
import {
  CLOSE_DIALOG,
  CLOSE_MATERIAL_ASSIGNMENT,
  CLOSE_MINISITE,
  CLOSE_POP_UP_NOTE,
  MEDIALINKS_CLOSE_CHOOSER,
  MEDIALINKS_MOVE_DIALOG_TO_FRONT,
  MEDIALINKS_OPEN_CHOOSER,
  MOVE_POP_UP_NOTE_TO_FRONT,
  OPEN_DIALOG,
  OPEN_MATERIAL_ASSIGNMENT,
  OPEN_MINISITE,
} from './actionNames';

import { postEvent } from '../api/analyticsApi';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../enums/analytics';
import dialogTypes from '../enums/dialogTypes';
import ENTITY_TYPE from '../enums/entityType';
import MedialinkKind from '../enums/medialinkKind';
import { getMedialinksInTocOrder } from '../selectors/medialinks';
import { getPageNumbersToShow } from '../selectors/rendering';
import api from '../services/api';
import { trackMedialinkEvent } from '../utils/medialink';

export function openMinisite(medialink, subLocationId) {
  return async dispatch => {
    const { data } = await api.get(`/studio/modules/${medialink.moduleId}/medialinks/${medialink.id}/mini-site`);

    await trackMedialinkEvent(medialink.id, subLocationId);

    return dispatch({
      type: OPEN_MINISITE,
      payload: {
        medialink,
        miniSiteUrl: data.url,
      },
    });
  };
}

export function openMedialink(medialink, subLocationId) {

  /**
   * Safari blocks window.open calls with target _blank that aren't tied to a user event.
   *
   * We bypass this for external media by storing a reference to the opened window outside of the async call
   * and setting the location manually inside the call.
   *
   * source: https://stackoverflow.com/questions/40362093/safari-window-open-doesnt-work
   */
    let windowRef;
    if (medialink.kind === MedialinkKind.EXTERNAL_MEDIA && medialink.externalMedia.type === 'weblink' && medialink.externalMedia.target === 'new-window') {
      windowRef = window.open();
    }

  return async dispatch => {
    if (!medialink) return null;

    if (!checkIsExercise(medialink.kind)) {
      // Event is captured in the exercise flow container
      await trackMedialinkEvent(medialink.id, subLocationId);
    }

    if (medialink.kind === MedialinkKind.EXTERNAL_MEDIA && medialink.externalMedia.type === 'weblink') {
      if (medialink.externalMedia.target === 'same-window') {
        window.open(medialink.externalMedia.href, '_self');
      }
      if (windowRef) {
        windowRef.location = medialink.externalMedia.href;
      }

      return null;
    }

    return dispatch({
      type: OPEN_DIALOG,
      payload: {
        id: medialink.id,
        subLocationId,
      },
    });
  };
}

export function openMaterialAssignmentModal(material, entityType, assignment, owner, subLocationId) {
  return async dispatch => {
    const {
      data: { result },
    } = await api.get(`/studio/user/modules/${material.moduleId}/assignments/${assignment.id}/exercise-session`);

    return dispatch({
      type: OPEN_MATERIAL_ASSIGNMENT,
      payload: {
        material: { ...material, isDone: Boolean(result) },
        entityType,
        assignment,
        owner,
        subLocationId,
      },
    });
  };
}

/**
 * Opens the media dialog
 *
 * @param {{ mediaLinks: String[] }} linkArea the link area object
 * @param {{ x: Number, y: Number }} mousePosition the mouse coordinates for the chooser
 */
export function linkAreaWithMedialinksClicked(linkArea, mousePosition) {
  return async (dispatch, getState) => {
    const { mediaLinks: medialinkIds } = linkArea;

    const state = getState();
    const medialinks = getMedialinksInTocOrder(state, medialinkIds);

    if (medialinks.length === 1) {
      const medialink = medialinks[0];
      if (medialink.miniSite) return dispatch(openMinisite(medialink));

      const assignment = medialink.assignments?.find(a => a.current);
      const shouldOpenMaterialAssignmentModal = Boolean(assignment) && !checkIsExercise(medialink.kind);
      if (shouldOpenMaterialAssignmentModal) return dispatch(openMaterialAssignmentModal(medialink, 'medialink', assignment, assignment.user));
      return dispatch(openMedialink(medialink, ANALYTICS_EVENT_SUBLOCATIONS.LINKAREA));
    }
    if (medialinks.length > 1) {
      return dispatch({
        type: MEDIALINKS_OPEN_CHOOSER,
        payload: {
          linkAreaId: linkArea.id,
          medialinkIds: medialinks.map(ml => ml.id),
          title: linkArea.name,
          mousePosition,
        },
      });
    }
    return null;
  };
}

export function closeDialog(id, dialogType) {
  return {
    type: CLOSE_DIALOG,
    payload: { id, dialogType },
  };
}

export function moveMediaDialogToFront(id, dialogType) {
  return {
    type: MEDIALINKS_MOVE_DIALOG_TO_FRONT,
    payload: { id, dialogType },
  };
}

export function closeMinisite(medialinkId) {
  return {
    type: CLOSE_MINISITE,
    payload: { medialinkId },
  };
}

export function closeMediaLinkChooser(linkAreaId) {
  return {
    type: MEDIALINKS_CLOSE_CHOOSER,
    payload: { linkAreaId },
  };
}

export function openMedialinkOptions(medialink, activeTab) {
  return {
    type: OPEN_DIALOG,
    payload: {
      id: medialink.id,
      dialogType: dialogTypes.OPTIONS,
      activeTab,
    },
  };
}

export function openPrintModal(activeNodes) {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_DIALOG,
      payload: {
        activeNodes,
        dialogType: dialogTypes.PRINT_PAGE,
        visiblePages: getPageNumbersToShow(getState()),
      },
    });
  };
}

export function openTocNodeOptionsModal(node, activeTab) {
  return {
    type: OPEN_DIALOG,
    payload: {
      dialogType: dialogTypes.TOCNODE_OPTIONS,
      node,
      activeTab,
    },
  };
}

export function openNoteOptionsModal(tocNodeId, noteId, activeTab) {
  return {
    type: OPEN_DIALOG,
    payload: {
      dialogType: dialogTypes.NOTE_OPTIONS,
      tocNodeId,
      id: noteId,
      activeTab,
    },
  };
}

export function openWhitepageOptionsModal(tocNodeId, whitepageId, activeTab) {
  return {
    type: OPEN_DIALOG,
    payload: {
      dialogType: dialogTypes.WHITEPAGE_OPTIONS,
      tocNodeId,
      id: whitepageId,
      activeTab,
    },
  };
}

export function openUserMaterial(userMaterial, subLocationId, metadata) {
  return async dispatch => {
    await postEvent({
      eventType: ANALYTICS_EVENT_TYPES.USER_MATERIAL_ACCESSED,
      objectId: userMaterial.id,
      subLocationId,
    });

    return dispatch({
      type: OPEN_DIALOG,
      payload: {
        ...metadata,
        id: userMaterial.id,
        entityType: ENTITY_TYPE.USER_MATERIAL,
      },
    });
  };
}

export function closeMaterialAssignmentModal() {
  return {
    type: CLOSE_MATERIAL_ASSIGNMENT,
  };
}

export function openAnnotationSetsManagementModal() {
  return {
    type: OPEN_DIALOG,
    payload: {
      dialogType: dialogTypes.ANNOTATION_SETS_MANAGEMENT,
    },
  };
}

export function openPopUpNote(tocNodeId, noteId) {
  return {
    type: OPEN_DIALOG,
    payload: {
      id: noteId,
      tocNodeId,
      entityType: ENTITY_TYPE.POP_UP_NOTE,
    },
  };
}

export function closePopUpNote(id) {
  return {
    type: CLOSE_POP_UP_NOTE,
    payload: {
      id,
    },
  };
}

export function movePopUpNoteToFront(id) {
  return {
    type: MOVE_POP_UP_NOTE_TO_FRONT,
    payload: {
      id,
    },
  };
}
