import React, { useReducer, useState, useCallback } from 'react';
import classNames from 'classnames';
import { func, arrayOf, shape, string, bool, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import PrintIcon from '@pelckmans/business-components/icons/Print';
import { useSelector } from 'react-redux';
import GenericModal from './common/GenericModal';
import { CustomRadioButton } from '../../../../components/CustomRadioButton';
import { CustomSelectbox } from '../../../../components/CustomSelectbox';
import Alert from '../../../../components/alert';
import { openTocNodeOptionsModal, closeDialog } from '../../../../actions/dialog';
import dialogTypes from '../../../../enums/dialogTypes';
import { getFeatureToggleFor } from '../../../../utils/feature-toggle';
import featureToggle from '../../../../enums/feature-toggle';
import { EMPTY_ARRAY } from '../../../../utils/stable-default-props';
import CustomSelect from '../../../../components/custom-select';
import { getActiveAnnotationSet, getAnnotationSets } from '../../../../selectors/annotationSets';
import PrintDigibookPages from '../../../../components/print-digibook-pages';

export const getNodeTitle = node => (node.displayPrefix ? `${node.prefix} ${node.title}` : node.title);
export const getNodePages = node => (node.fromPage !== node.toPage ? `pg. ${node.fromPage} - pg. ${node.toPage}` : `pg. ${node.fromPage}`);
export const getNodeString = node => `${getNodeTitle(node)} (${getNodePages(node)})`;

export default function PrintPageRangeModal({ dispatch, activeNodes = EMPTY_ARRAY, visiblePages, teacherFeaturesEnabled = false, digibook }) {
  const [t] = useTranslation();
  const [printOpts, setPrintOpts] = useState(null);
  const isSharingEnabled = getFeatureToggleFor(featureToggle.SHARING_MATERIAL);

  const annotationSets = useSelector(getAnnotationSets);
  const activeAnnotationSet = useSelector(getActiveAnnotationSet);

  const { teacherDigitalManualLicense: hasTeacherDigitalManualLicense, teacherDemoProductLicense: hasTeacherDemoProductLicense } = digibook;

  const initialState = {
    includeAnswerLayer: false,
    checkedRadio: `page-${visiblePages[0]}`,
    manualEnteredPageNumber: visiblePages[0],
    manualEnteredPageNumberInvalid: false,
    renderingPagesToPrint: false,
    includeAnnotations: false,
    annotationSetId: activeAnnotationSet.id,
  };

  function isInvalidPageNumber(page) {
    return page < 0 || page > digibook.totalPages + 1 || Number.isNaN(page) || page === '';
  }

  const closeSelf = useCallback(() => dispatch(closeDialog(undefined, dialogTypes.PRINT_PAGE)), [dispatch]);

  function reducer(state, action) {
    switch (action.type) {
      case 'toggle-include-answerLayer': {
        return { ...state, includeAnswerLayer: !state.includeAnswerLayer };
      }
      case 'changeRadio': {
        return { ...state, checkedRadio: action.value };
      }
      case 'manualEntryChanged': {
        return {
          ...state,
          checkedRadio: 'manualEntry',
          manualEnteredPageNumber: action.value,
          manualEnteredPageNumberInvalid: isInvalidPageNumber(action.value),
        };
      }
      case 'toggle-rendering': {
        return { ...state, renderingPagesToPrint: !state.renderingPagesToPrint };
      }
      case 'toggle-include-annotations': {
        return { ...state, includeAnnotations: !state.includeAnnotations };
      }
      case 'set-annotation-set-id': {
        return { ...state, annotationSetId: parseInt(action.value, 10) };
      }
      default: {
        return state;
      }
    }
  }

  const [state, localDispatch] = useReducer(reducer, initialState);

  async function print() {
    if (state.renderingPagesToPrint) return;
    switch (state.checkedRadio) {
      case 'manualEntry': {
        if (!state.manualEnteredPageNumberInvalid) {
          localDispatch({ type: 'toggle-rendering' });
          setPrintOpts({ from: parseInt(state.manualEnteredPageNumber, 10) });
        }
        break;
      }
      case 'both': {
        localDispatch({ type: 'toggle-rendering' });
        setPrintOpts({ from: visiblePages[0], to: visiblePages[1] });
        break;
      }
      default: {
        localDispatch({ type: 'toggle-rendering' });
        setPrintOpts({ from: parseInt(state.checkedRadio.replace('page-', ''), 10) });
        break;
      }
    }
  }

  const cancelPrint = useCallback(() => {
    localDispatch({ type: 'toggle-rendering' });
    setPrintOpts(null);
  }, []);

  const footerContent = (
    <>
      {hasTeacherDemoProductLicense && !hasTeacherDigitalManualLicense ? (
        <button type="button" className="pbb-btn pbb-btn--inverted" onClick={closeSelf}>
          {t('printing.modal.buttons.close')}
        </button>
      ) : (
        <>
          <button type="button" className="pbb-btn pbb-btn--inverted" onClick={closeSelf}>
            {t('printing.modal.buttons.cancel')}
          </button>

          <button type="button" className={classNames('pbb-btn', { 'pbb-btn--busy': state.renderingPagesToPrint })} onClick={print}>
            {t('printing.modal.buttons.print')}
          </button>
        </>
      )}
    </>
  );

  function handleNodeClick(e, node) {
    e.preventDefault();
    dispatch(openTocNodeOptionsModal(node));
  }

  return (
    <GenericModal
      close={closeSelf}
      title={t('printing.modal.title')}
      footerContent={footerContent}
      icon={<PrintIcon />}
      contentModifier={!teacherFeaturesEnabled ? 'pbb-modal__content--md' : 'pbb-modal__print'} // students get small dialog
    >
      {hasTeacherDemoProductLicense && !hasTeacherDigitalManualLicense ? (
        <Alert message={t('printing.modal.demoProductDisclaimer')} />
      ) : (
        <>
          {printOpts && (
            <PrintDigibookPages
              digibook={digibook}
              from={printOpts.from}
              to={printOpts.to}
              includeAnswers={state.includeAnswerLayer}
              onAfterPrint={cancelPrint}
              annotationSetId={state.annotationSetId}
              includeAnnotations={state.includeAnnotations}
            />
          )}
          {teacherFeaturesEnabled && digibook.answerLayer && (
            <>
              <CustomSelectbox id="include-answers" checked={state.includeAnswerLayer} onChange={() => localDispatch({ type: 'toggle-include-answerLayer' })}>
                <span dangerouslySetInnerHTML={{ __html: t('printing.modal.includeAnswerLayers') }} />
              </CustomSelectbox>
            </>
          )}

          <CustomSelectbox id="include-annotations" checked={state.includeAnnotations} onChange={() => localDispatch({ type: 'toggle-include-annotations' })}>
            <span dangerouslySetInnerHTML={{ __html: teacherFeaturesEnabled ? t('printing.modal.includeAnnotations') : t('printing.modal.includeAnnotationSet') }} />

            {teacherFeaturesEnabled && (
              <CustomSelect
                value={state.annotationSetId.toString()}
                onChange={e => {
                  localDispatch({ type: 'set-annotation-set-id', value: e.target.value });
                }}
                disabled={!state.includeAnnotations}
              >
                {annotationSets.map(annotationSet => (
                  <option key={annotationSet.id} value={annotationSet.id}>
                    {annotationSet.name}
                  </option>
                ))}
              </CustomSelect>
            )}
          </CustomSelectbox>

          <div className="divider-line" />

          <div
            className={classNames({
              'pbb-modal__print-wrapper': teacherFeaturesEnabled,
              'pbb-modal__print-wrapper--space-between': teacherFeaturesEnabled && visiblePages.length > 1,
            })}
          >
            {visiblePages.map(page => {
              const key = `page-${page}`;
              return (
                <CustomRadioButton
                  key={key}
                  value={key}
                  name="printRange"
                  onChange={() => {
                    localDispatch({ type: 'changeRadio', value: key });
                  }}
                  checked={state.checkedRadio === key}
                >
                  {t('printing.modal.page')}
                  &nbsp;
                  {page}
                </CustomRadioButton>
              );
            })}

            {visiblePages.length > 1 && (
              <CustomRadioButton
                key="both"
                value="both"
                name="printRange"
                onChange={() => {
                  localDispatch({ type: 'changeRadio', value: 'both' });
                }}
                checked={state.checkedRadio === 'both'}
              >
                {t('printing.modal.page')}
                &nbsp;
                {visiblePages.join('-')}
              </CustomRadioButton>
            )}

            <CustomRadioButton
              key="manualEntry"
              value="manualEntry"
              name="printRange"
              onChange={() => {
                localDispatch({ type: 'changeRadio', value: 'manualEntry' });
              }}
              checked={state.checkedRadio === 'manualEntry'}
              className="pbb-page-selection"
            >
              {t('printing.modal.otherPage')}
              &nbsp;
              <input
                data-testid="input-manualEntry"
                type="number"
                className={classNames('page-number', { warning: state.manualEnteredPageNumberInvalid })}
                value={state.manualEnteredPageNumber}
                onChange={e => {
                  localDispatch({ type: 'manualEntryChanged', value: e.target.value });
                }}
              />
            </CustomRadioButton>
          </div>

          {teacherFeaturesEnabled && activeNodes.length > 0 && (
            <>
              <div className="divider-line" />
              <div className="pbb-modal__print-links">
                <div className="pbb-modal__print-links-title">{isSharingEnabled ? t('printing.modal.moreShareOptions') : t('printing.modal.moreOptions')}</div>
                <ul>
                  {activeNodes.map(node => (
                    <li key={node.id}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a href="#" onClick={e => handleNodeClick(e, node)}>
                        {getNodeString(node)}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </>
      )}
    </GenericModal>
  );
}

PrintPageRangeModal.propTypes = {
  activeNodes: arrayOf(
    shape({
      fromPage: number,
      toPage: number,
      title: string,
      displayPrefix: bool,
      prefix: string,
    }),
  ),
  digibook: shape({
    totalPages: number.isRequired,
    answerLayer: string,
    teacherDemoProductLicense: bool,
    teacherDigitalManualLicense: bool,
  }).isRequired,
  visiblePages: arrayOf(number).isRequired,
  teacherFeaturesEnabled: bool,
  dispatch: func.isRequired,
};
