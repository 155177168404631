import React, { useEffect, useState, useMemo } from 'react';
import { shape, string, oneOf, bool, number } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { ANNOTATION_PADDING } from '@pelckmans/business-components/components/text-annotations/constants';
import { whitepageRendered } from '../../../../actions/player';
import Whitepage from './whitepage';
import PaperType from '../../../../enums/paperType';
import blank from './paper-types/blank.png';
import raster from './paper-types/raster.png';
import media from './paper-types/media.png';
import lines from './paper-types/lines.png';
import withDimensions from '../../../../hocs/with-dimensions';
import spinner from '../../../../../assets/images/spinner.svg';
import { migrateOldAnnotation } from '../book/text-annotation/migrate-old-annotation';

const paperTypes = {
  blank,
  raster,
  media,
  lines,
};

function migrateAnnotations(annotations, spreadWidth, spreadHeight) {
  return annotations.map(({ migrated, ...annotation }) => {
    if (annotation.fontId) return migrateOldAnnotation(annotation, spreadWidth, spreadHeight);
    if (!migrated) return annotation;

    return {
      ...annotation,
      top: annotation.top * spreadHeight - ANNOTATION_PADDING,
      left: annotation.left * spreadWidth - ANNOTATION_PADDING,
      width: annotation.width ? annotation.width * spreadWidth : annotation.width,
      height: !annotation.height || annotation.height > 1 ? annotation.height : annotation.height * spreadHeight,
    };
  });
}

async function loadJSON(url) {
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    if (e.response.status === 403) {
      return [];
    }
    throw e;
  }
}

async function loadImage(src) {
  const image = new Image();
  return new Promise(resolve => {
    image.onload = () => {
      resolve(image);
    };

    image.crossOrigin = 'anonymous';
    image.src = src;
  });
}

function WhitepageContainer(props) {
  const [t] = useTranslation();
  const { whitepage, throttleSave = true, dimensions } = props;
  const [drawings, setDrawings] = useState(null);
  const [annotations, setAnnotations] = useState(null);
  const [paper, setPaper] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const paperImagePromise = useMemo(() => loadImage(paperTypes[whitepage.paperType]), [whitepage.paperType]);
  const drawingDataPromise = useMemo(() => loadJSON(whitepage.url), [whitepage.url]);
  const annotationDataPromise = useMemo(() => loadJSON(whitepage.annotationsUrl), [whitepage.annotationsUrl]);

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);

      const [paperImage, drawingData, annotationData] = await Promise.all([paperImagePromise, drawingDataPromise, annotationDataPromise]);
      setPaper(paperImage);
      setDrawings(drawingData);
      setAnnotations(migrateAnnotations(annotationData, paperImage.width, paperImage.height));

      setIsLoading(false);
      dispatch(whitepageRendered());
    }

    loadData();
  }, [dispatch, paperImagePromise, drawingDataPromise, annotationDataPromise]);

  useEffect(() => {
    return () => {
      if (paper) paper.src = '';
    };
  }, [paper]);

  return isLoading ? (
    <div className="pbb-book-loader" data-testid="whitepage-spinner">
      <img src={spinner} alt={t('book.loading')} />
    </div>
  ) : (
    paper && (
      <Whitepage
        whitepage={whitepage}
        initialDrawings={drawings}
        initialAnnotations={annotations}
        paper={paper}
        throttleSave={throttleSave}
        dimensions={dimensions}
        paperTypes={paperTypes}
      />
    )
  );
}

WhitepageContainer.propTypes = {
  whitepage: shape({
    id: string.isRequired,
    title: string,
    url: string.isRequired,
    annotationsUrl: string.isRequired,
    paperType: oneOf(Object.values(PaperType)),
  }).isRequired,
  throttleSave: bool,
  dimensions: shape({
    height: number.isRequired,
    width: number.isRequired,
  }).isRequired,
};

export default withDimensions(WhitepageContainer);
