/* eslint-disable react/no-danger */
import { ShareManager } from '@pelckmans/business-components/components/share-manager';
import classNames from 'classnames';
import { bool, func, number, oneOf, shape, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { closeDialog } from '../../../../../actions/dialog';
import { errorOccurred } from '../../../../../actions/error';
import { getTeacherSharedSolutionNodes } from '../../../../../actions/sharing';
import { CustomRadioButton } from '../../../../../components/CustomRadioButton';
import { CustomSelectbox } from '../../../../../components/CustomSelectbox';
import Alert from '../../../../../components/alert';
import CustomSelect from '../../../../../components/custom-select';
import { STUDIO_GROUP } from '../../../../../constants/constants';
import dialogTypes from '../../../../../enums/dialogTypes';
import FeatureToggle from '../../../../../enums/feature-toggle';
import { tokenGetter } from '../../../../../services/api';
import { getFeatureToggleFor } from '../../../../../utils/feature-toggle';
import getMyClassesLink from '../../../../../utils/getMyClassesLink';
import { getNodeString, getNodeTitle } from '../PrintPageRangeModal';
import GenericModal from '../common/GenericModal';
import Tab from '../tabs/tab';
import Tabs from '../tabs/tabs';
import { getActiveAnnotationSet, getAnnotationSets } from '../../../../../selectors/annotationSets';
import { getTeacherFeaturesEnabledFor } from '../../../../../selectors/digibooks';
import PrintDigibookPages from '../../../../../components/print-digibook-pages';

const range = (start, end) => Array.from({ length: end + 1 - start }, (v, k) => k + start);

const tabs = {
  PRINT: 'print',
  SHARING_SOLUTIONS: 'sharing_solutions',
};

export default function TocNodeOptionsModal({ dispatch, node, digibook, activeTab }) {
  const isSharingEnabled = getFeatureToggleFor(FeatureToggle.SHARING_MATERIAL) && digibook.teacherDigitalManualLicense;
  const multiPagePrintsWithoutAnswersEnabled = getFeatureToggleFor(FeatureToggle.MULTI_PAGE_PRINTS_WITHOUT_ANSWERS);

  const isTocNodeMappedToPages = Object.prototype.hasOwnProperty.call(node, 'fromPage') && Object.prototype.hasOwnProperty.call(node, 'toPage');
  const allowedToPrint = isTocNodeMappedToPages && (digibook.teacherDigitalManualLicense || !digibook.teacherDemoProductLicense);

  const { t } = useTranslation();

  const renderShareManager = isTocNodeMappedToPages && digibook.answerLayer !== undefined;

  const activeAnnotationSet = useSelector(getActiveAnnotationSet);
  const annotationSets = useSelector(getAnnotationSets);
  const teacherFeaturesEnabled = useSelector(state => getTeacherFeaturesEnabledFor(state));

  const [currentTab, setCurrentTab] = useState(isSharingEnabled ? tabs.SHARING_SOLUTIONS : tabs.PRINT);
  const [printOpts, setPrintOpts] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(!isSharingEnabled || (isSharingEnabled && !renderShareManager));

  const tokenAccessor = useMemo(() => ({ tokenGetter: tokenGetter(dispatch) }), [dispatch]);

  const initialState = {
    includeAnswerLayer: teacherFeaturesEnabled ? !!digibook.answerLayer : false,
    checkedRadio: digibook.answerLayer ? 'wholeNode' : 'specificPage',
    fromPageSelectValue: node.fromPage,
    toPageSelectValue: node.toPage,
    renderingPagesToPrint: false,
    includeAnnotations: false,
    annotationSetId: activeAnnotationSet.id,
  };

  const closeSelf = useCallback(() => dispatch(closeDialog(undefined, dialogTypes.TOCNODE_OPTIONS)), [dispatch]);
  const showModal = useCallback(() => setIsModalVisible(true), []);
  const handleError = useCallback(() => dispatch(errorOccurred({ status: 500, originalError: 'error in share manager component' })), [dispatch]);

  function reducer(state, action) {
    switch (action.type) {
      case 'toggle-include-answerLayer': {
        const answerLayerIncluded = !state.includeAnswerLayer;

        const newCheckedRadio = answerLayerIncluded ? 'wholeNode' : 'specificPage';

        const checkedRadio = multiPagePrintsWithoutAnswersEnabled ? state.checkedRadio : newCheckedRadio;

        return {
          ...state,
          includeAnswerLayer: answerLayerIncluded,
          checkedRadio,
        };
      }
      case 'toggle-include-annotations': {
        return { ...state, includeAnnotations: !state.includeAnnotations };
      }
      case 'set-annotation-set-id': {
        return { ...state, annotationSetId: parseInt(action.value, 10) };
      }
      case 'set-range-wholeNode': {
        return { ...state, checkedRadio: 'wholeNode' };
      }
      case 'set-range-specficRange': {
        return { ...state, checkedRadio: 'range' };
      }
      case 'set-range-specificPage': {
        return { ...state, checkedRadio: 'specificPage' };
      }
      case 'change-fromPage': {
        return { ...state, fromPageSelectValue: parseInt(action.value, 10) };
      }
      case 'change-toPage': {
        return { ...state, toPageSelectValue: parseInt(action.value, 10) };
      }
      case 'toggle-rendering': {
        return { ...state, renderingPagesToPrint: !state.renderingPagesToPrint };
      }
      default:
        return state;
    }
  }

  const [state, localDispatch] = useReducer(reducer, initialState);

  async function print() {
    if (state.renderingPagesToPrint) return;

    switch (state.checkedRadio) {
      case 'wholeNode': {
        localDispatch({ type: 'toggle-rendering' });
        setPrintOpts({ from: node.fromPage, to: node.toPage });
        break;
      }
      case 'range': {
        localDispatch({ type: 'toggle-rendering' });
        setPrintOpts({ from: state.fromPageSelectValue, to: state.toPageSelectValue });
        break;
      }
      case 'specificPage': {
        localDispatch({ type: 'toggle-rendering' });
        setPrintOpts({ from: state.fromPageSelectValue });
        break;
      }
      default:
    }
  }

  const cancelPrint = useCallback(() => {
    localDispatch({ type: 'toggle-rendering' });
    setPrintOpts(null);
  }, []);

  const getFooterContent = () => {
    switch (currentTab) {
      case tabs.PRINT:
        return allowedToPrint ? (
          <>
            <button type="button" className="pbb-btn pbb-btn--inverted" onClick={closeSelf}>
              {t('printing.modal.buttons.cancel')}
            </button>
            <button type="button" className={classNames('pbb-btn', { 'pbb-btn--busy': state.renderingPagesToPrint })} onClick={print}>
              {t('printing.modal.buttons.print')}
            </button>
          </>
        ) : (
          <button type="button" className="pbb-btn pbb-btn--inverted" onClick={closeSelf}>
            {t('printing.modal.buttons.close')}
          </button>
        );
      case tabs.SHARING_SOLUTIONS:
        if (isSharingEnabled && !renderShareManager) {
          return (
            <button type="button" className="pbb-btn pbb-btn--inverted" onClick={closeSelf}>
              {t('sharing.solutions.modal.buttons.close')}
            </button>
          );
        }
        return null;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (activeTab) setCurrentTab(activeTab);
  }, [activeTab]);

  const renderIncludeAnswersCheckbox = isTocNodeMappedToPages && digibook.answerLayer;

  return (
    <div className={isModalVisible ? '' : 'pbb-modal-wrapper--hidden'} data-testid="modal-wrapper-hidden">
      <GenericModal
        title={getNodeTitle(node)}
        footerContent={getFooterContent()}
        icon="icon-book"
        tabBar={
          <Tabs onClick={setCurrentTab} activeTab={currentTab}>
            {isSharingEnabled && <Tab title={t('tabs.sharing_solutions')} icon="bc-icon bc-icon-lightbulb" id={tabs.SHARING_SOLUTIONS} />}
            <Tab title={t('tabs.print')} id={tabs.PRINT} icon="icon-bb-print" />
          </Tabs>
        }
        close={closeSelf}
        contentModifier={classNames('pbb-modal__tocNodeOptions', {
          'pbb-modal__tocNodeOptions--large': isSharingEnabled && renderShareManager,
        })}
      >
        {printOpts && (
          <PrintDigibookPages
            digibook={digibook}
            from={printOpts.from}
            to={printOpts.to}
            includeAnswers={state.includeAnswerLayer}
            annotationSetId={state.annotationSetId}
            includeAnnotations={state.includeAnnotations}
            onAfterPrint={cancelPrint}
          />
        )}

        {currentTab === tabs.PRINT &&
          (digibook.teacherDemoProductLicense && !digibook.teacherDigitalManualLicense ? (
            <div className="pbb-print-container">
              <div className="pbb-alert">
                <span dangerouslySetInnerHTML={{ __html: t('printing.modal.demoProductDisclaimer') }} />
              </div>
            </div>
          ) : (
            <div className="pbb-print-container" data-testid="print-tab-content">
              {!isTocNodeMappedToPages && (
                <div className="pbb-alert">
                  <span data-testid="message" dangerouslySetInnerHTML={{ __html: t('printing.modal.notMapped') }} />
                </div>
              )}

              {isTocNodeMappedToPages && digibook.answerLayer && teacherFeaturesEnabled && (
                <>
                  <CustomSelectbox
                    id="include-answers"
                    checked={state.includeAnswerLayer}
                    onChange={() => localDispatch({ type: 'toggle-include-answerLayer' })}
                    dataTestId="include-answers"
                  >
                    <span dangerouslySetInnerHTML={{ __html: t('printing.modal.includeAnswerLayers') }} />
                  </CustomSelectbox>
                </>
              )}

              {isTocNodeMappedToPages && (
                <CustomSelectbox id="include-annotations" checked={state.includeAnnotations} onChange={() => localDispatch({ type: 'toggle-include-annotations' })}>
                  <span dangerouslySetInnerHTML={{ __html: teacherFeaturesEnabled ? t('printing.modal.includeAnnotations') : t('printing.modal.includeAnnotationSet') }} />
                  {teacherFeaturesEnabled && (
                    <CustomSelect
                      value={state.annotationSetId.toString()}
                      onChange={e => {
                        localDispatch({ type: 'set-annotation-set-id', value: e.target.value });
                      }}
                      disabled={!state.includeAnnotations}
                    >
                      {annotationSets.map(annotationSet => (
                        <option key={annotationSet.id} value={annotationSet.id}>
                          {annotationSet.name}
                        </option>
                      ))}
                    </CustomSelect>
                  )}
                </CustomSelectbox>
              )}

              {renderIncludeAnswersCheckbox || isTocNodeMappedToPages ? <div className="divider-line" /> : null}

              {isTocNodeMappedToPages && (multiPagePrintsWithoutAnswersEnabled || state.includeAnswerLayer) && (
                <>
                  <CustomRadioButton
                    value="wholeNode"
                    name="printRange"
                    onChange={() => localDispatch({ type: 'set-range-wholeNode' })}
                    checked={state.checkedRadio === 'wholeNode'}
                    data-testid="wholeNode"
                  >
                    {t('printing.modal.everythingOf')}
                    &nbsp;
                    {getNodeString(node)}
                  </CustomRadioButton>

                  {node.toPage > node.fromPage && (
                    <CustomRadioButton
                      value="range"
                      name="printRange"
                      onChange={() => localDispatch({ type: 'set-range-specficRange' })}
                      checked={state.checkedRadio === 'range'}
                      className="radio pbb-page-selection"
                      data-testid="printRange"
                    >
                      {t('printing.modal.page')}
                      &nbsp;
                      <div className="pbb-page-select">
                        <CustomSelect
                          testId="select-range-from"
                          onChange={e => {
                            localDispatch({ type: 'set-range-specficRange' });
                            localDispatch({ type: 'change-fromPage', value: e.target.value });
                          }}
                          value={state.fromPageSelectValue.toString()}
                        >
                          {range(node.fromPage, state.toPageSelectValue).map(i => (
                            <option key={`from-${i}`}>{i}</option>
                          ))}
                        </CustomSelect>
                      </div>
                      &nbsp; tot &nbsp;
                      <CustomSelect
                        testId="select-range-to"
                        onChange={e => {
                          localDispatch({ type: 'set-range-specficRange' });
                          localDispatch({ type: 'change-toPage', value: e.target.value });
                        }}
                        value={state.toPageSelectValue.toString()}
                      >
                        {range(state.fromPageSelectValue, node.toPage).map(i => (
                          <option key={`to-${i}`}>{i}</option>
                        ))}
                      </CustomSelect>
                    </CustomRadioButton>
                  )}
                </>
              )}

              {!multiPagePrintsWithoutAnswersEnabled && isTocNodeMappedToPages && !state.includeAnswerLayer && (
                <CustomRadioButton
                  value="specificPage"
                  name="printRange"
                  onChange={() => localDispatch({ type: 'set-range-specificPage' })}
                  checked={state.checkedRadio === 'specificPage'}
                  className="radio pbb-page-selection"
                >
                  {t('printing.modal.page')}
                  &nbsp;
                  <CustomSelect
                    testId="select-single"
                    onChange={e => {
                      localDispatch({ type: 'change-fromPage', value: e.target.value });
                    }}
                    value={state.fromPageSelectValue.toString()}
                  >
                    {range(node.fromPage, node.toPage).map(i => (
                      <option key={`from-${i}`}>{i}</option>
                    ))}
                  </CustomSelect>
                </CustomRadioButton>
              )}
            </div>
          ))}

        {currentTab === tabs.SHARING_SOLUTIONS && isSharingEnabled && !renderShareManager && (
          <div>
            {/* the wrapper element inherits the height of it's parent, therefor a wrapper is needed here */}
            <Alert message={t('sharing.solutions.modal.notMapped')} />
          </div>
        )}

        {isSharingEnabled && renderShareManager && (
          <div className={classNames('pbb-share-manager', { 'pbb-share-manager--hidden': currentTab !== tabs.SHARING_SOLUTIONS })}>
            <ShareManager
              moduleId={digibook.module}
              nodeId={node.id}
              materialType="solution"
              materialId={digibook.id}
              tocNodeRange={{ from: node.fromPage, to: node.toPage }}
              tokenAccessor={tokenAccessor}
              onCancel={closeSelf}
              onSave={() => {
                closeSelf();
                dispatch(getTeacherSharedSolutionNodes());
              }}
              onError={handleError}
              onGroupsFetched={showModal}
              translations={{
                sharingDisabled: t('shareManager.sharingDisabled'),
                sharingEnabled: t('shareManager.sharingEnabled'),
                cancel: t('shareManager.cancel'),
                save: t('shareManager.save'),
                rangeSeparator: t('shareManager.rangeSeparator'),
                overrideRangeWarning: t('shareManager.overrideRangeWarning'),
                noGroupsMessage: t('shareManager.solutions.noGroupsMessage', {
                  myClassesLink: getMyClassesLink(),
                }),
                close: t('shareManager.close'),
                search: t('shareManager.search'),
                noSearchResults: t('shareManager.noSearchResults'),
              }}
              studioGroup={STUDIO_GROUP}
            />
          </div>
        )}
      </GenericModal>
    </div>
  );
}

TocNodeOptionsModal.propTypes = {
  node: shape({
    id: string.isRequired,
    fromPage: number,
    toPage: number,
    title: string,
    displayPrefix: bool,
    prefix: string,
  }).isRequired,
  digibook: shape({
    answerLayer: string,
    systemToken: string,
    totalPages: number,
    teacherDemoProductLicense: bool,
    teacherDigitalManualLicense: bool,
  }).isRequired,
  dispatch: func.isRequired,
  activeTab: oneOf([tabs.PRINT, tabs.SHARING_SOLUTIONS]),
};
