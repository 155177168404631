export const SET_VIEWMODE = 'NAVIGATION_SET_VIEWMODE';
export const SET_CURRENTPAGE = 'NAVIGATION_SET_CURRENTPAGE';
export const TOC_NODE_CLICKED = 'NAVIGATION_TOC_NODE_CLICKED';
export const SET_IS_MOBILE_WIDTH = 'NAVIGATION_SET_IS_MOBILE_WIDTH';
export const SHOW_ALL_ANSWERS = 'NAVIGATION_SHOW_ALL_ANSWERS';
export const HIDE_ALL_ANSWERS = 'NAVIGATION_HIDE_ALL_ANSWERS';
export const REVEAL_PARTIAL_ANSWER = 'NAVIGATION_REVEAL_PARTIAL_ANSWER';
export const REVEAL_STEPPER_ANSWER = 'NAVIGATION_REVEAL_STEPPER_ANSWER';
export const HIDE_STEPPER_ANSWER = 'NAVIGATION_HIDE_STEPPER_ANSWER';
export const HIDE_PARTIAL_ANSWER = 'NAVIGATION_HIDE_PARTIAL_ANSWER';

export const ZOOM_IN = 'NAVIGATION_ZOOM_IN';
export const ZOOM_OUT = 'NAVIGATION_ZOOM_OUT';
export const ZOOM_TO_FIT = 'NAVIGATION_ZOOM_TO_FIT';
export const SET_ZOOM_LEVEL = 'NAVIGATION_SET_ZOOM_LEVEL';
export const DISABLE_ZOOM_TO_FIT_MODE = 'NAVIGATION_DISABLE_ZOOM_TO_FIT_MODE';
export const DISABLE_ZOOM_TYPE = 'NAVIGATION_DISABLE_ZOOM_TYPE';

export const AUTHENTICATE_SUCCESS = 'AUTH_AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILURE = 'AUTH_AUTHENTICATE_FAILURE';
export const REFRESH_TOKEN_SUCCESS = 'AUTH_REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'AUTH_REFRESH_TOKEN_FAILURE';

export const MEDIALINKS_FETCH_BY_NODEID = 'MEDIALINKS_FETCH_BY_NODEID';

export const MEDIALINKS_FETCH_BY_NODEID_SUCCESS = 'MEDIALINKS_FETCH_BY_NODEID_SUCCESS';
export const MEDIALINKS_FETCH_BY_IDS_SUCCESS = 'MEDIALINKS_FETCH_BY_IDS_SUCCESS';

export const MEDIALINKS_FETCH_BY_NODEID_FAILED = 'MEDIALINKS_FETCH_BY_NODEID_FAILED';
export const MEDIALINKS_MOVE_DIALOG_TO_FRONT = 'MEDIALINKS_MOVE_DIALOG_TO_FRONT';
export const MEDIALINKS_OPEN_CHOOSER = 'MEDIALINKS_OPEN_CHOOSER';
export const MEDIALINKS_CLOSE_CHOOSER = 'MEDIALINKS_CLOSE_CHOOSER';
export const MEDIALINKS_FETCH_CONTENTTYPES_SUCCESS = 'MEDIALINKS_FETCH_CONTENTTYPES_SUCCESS';
export const MEDIALINK_SHARED = 'MEDIALINK_SHARED';
export const MEDIALINK_SET_EXERCISE_SESSION_RESULT = 'MEDIALINK_SET_EXERCISE_SESSION_RESULT';
export const MEDIALINKS_ADD_FAVORITE_SUCCESS = 'USER_ADD_FAVORITE_MEDIALINK_SUCCESS';
export const MEDIALINKS_REMOVE_FAVORITE_SUCCESS = 'USER_REMOVE_FAVORITE_MEDIALINK_SUCCESS';

export const SLIDE_SETS_FETCH_BY_NODE_ID_SUCCESS = 'SLIDE_SETS_FETCH_BY_NODE_ID_SUCCESS';
export const SLIDE_SETS_REMOVE_SLIDE_SET_SUCCESS = 'SLIDE_SETS_REMOVE_SLIDE_SET_SUCCESS';
export const SLIDE_SETS_SHARE = 'SLIDE_SETS_SHARE';

export const INITIALIZE = 'PLAYER_INITIALIZE';
export const INITIALIZE_SUCCESS = 'PLAYER_INITIALIZE_SUCCESS';

export const BOOKLAYER_PARSED = 'PLAYER_BOOKLAYER_PARSED';
export const BOOKLAYER_RENDERED = 'PLAYER_BOOKLAYER_RENDERED';

export const WHITEPAGE_RENDERED = 'PLAYER_WHITEPAGE_RENDERED';

export const SET_PLAYER_MODE = 'PLAYER_SET_PLAYER_MODE';

export const SET_CURRENT_TOOL = 'TOOLS_SET_CURRENT_TOOL';
export const SET_CURRENT_MATH_TOOL = 'TOOLS_SET_CURRENT_MATH_TOOL';
export const TOGGLE_ANSWER_REVEAL = 'TOOLS_TOGGLE_ANSWER_REVEAL';
export const TOGGLE_ANSWER_HIDE = 'TOOLS_TOGGLE_ANSWER_HIDE';
export const SET_COLOR = 'TOOLS_SET_COLOR';
export const SET_BACKGROUND_COLOR = 'TOOLS_SET_BACKGROUND_COLOR';
export const SET_SIZE = 'TOOLS_SET_SIZE';
export const MARKING_CREATED = 'TOOLS_MARKING_CREATED';
export const MARKINGS_CREATED = 'TOOLS_MARKINGS_CREATED';
export const MARKINGS_LOADED = 'TOOLS_MARKINGS_LOADED';
export const MARKINGS_DELETE_FOR_SPREAD = 'TOOLS_MARKINGS_DELETE_FOR_SPREAD';
export const TOGGLE_ANSWER_STEPPER = 'TOOLS_TOGGLE_ANSWER_STEPPER';

export const MODULE_FETCH_SUCCESS = 'MODULE_FETCH_SUCCESS';

export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const OPEN_MINISITE = 'OPEN_MINISITE';
export const CLOSE_MINISITE = 'CLOSE_MINISITE';

export const RETRIEVE_FAVORITES_SUCCESS = 'USER_RETRIEVE_FAVORITES_SUCCESS';

export const RETRIEVE_CATEGORIES_TO_HIDE_SUCCESS = 'USER_RETRIEVE_CATEGORIES_TO_HIDE_SUCCESS';
export const ADD_CATEGORY_TO_HIDE_SUCCESS = 'USER_ADD_CATEGORY_TO_HIDE_SUCCESS';
export const REMOVE_CATEGORY_TO_HIDE_SUCCESS = 'USER_REMOVE_CATEGORY_TO_HIDE_SUCCESS';
export const SET_INITIAL_TOOLBAR_POSITION = 'USER_SET_INITIAL_TOOLBAR_POSITION';

export const ERROR_OCCURRED = 'ERROR_ERROR_OCCURRED';

export const NOTES_SAVE_NOTE_SUCCESS = 'NOTES_SAVE_NOTE_SUCCESS';
export const NOTES_DELETE_NOTE_SUCCESS = 'NOTES_DELETE_NOTE_SUCCESS';
export const NOTES_FETCH_BY_NODEID_SUCCESS = 'NOTES_FETCH_BY_NODEID_SUCCESS';

export const WHITEPAGES_ADD_SUCCESS = 'WHITEPAGES_ADD_SUCCESS';
export const WHITEPAGES_DELETE_SUCCESS = 'WHITEPAGES_DELETE_SUCCESS';
export const WHITEPAGES_FETCH_BY_NODEID_SUCCESS = 'WHITEPAGES_FETCH_BY_NODEID_SUCCESS';
export const WHITEPAGES_FETCH_SINGLE_SUCCESS = 'WHITEPAGES_FETCH_SINGLE_SUCCESS';
export const WHITEPAGES_SAVE_SUCCESS = 'WHITEPAGES_SAVE_SUCCESS';
export const WHITEPAGES_SAVE_OPTIMISTIC_UPDATE = 'WHITEPAGES_SAVE_OPTIMISTIC_UPDATE';
export const WHITEPAGES_ADD_FAVORITE_SUCCESS = 'USER_ADD_FAVORITE_WHITEPAGE_SUCCESS';
export const WHITEPAGES_REMOVE_FAVORITE_SUCCESS = 'USER_REMOVE_FAVORITE_WHITEPAGE_SUCCESS';

export const SHARING_GET_TEACHER_SHARED_SOLUTION_NODES = 'SHARING_GET_TEACHER_SHARED_SOLUTION_NODES';

export const FEATURE_TOGGLES_RETRIEVED = 'FEATURE_TOGGLES_RETRIEVED';

export const USER_MEDIA_RETRIEVED = 'USER_MEDIA_RETRIEVED';
export const ADD_USER_MEDIA = 'ADD_USER_MEDIA';
export const USER_MEDIA_ADD_FAVORITE_SUCCESS = 'USER_MEDIA_ADD_FAVORITE_SUCCESS';
export const USER_MEDIA_REMOVE_FAVORITE_SUCCESS = 'USER_MEDIA_REMOVE_FAVORITE_SUCCESS';
export const USER_MEDIA_DELETE_SUCCESS = 'USER_MEDIA_DELETE_SUCCESS';
export const USER_MEDIA_SAVE_SUCCESS = 'USER_MEDIA_SAVE_SUCCESS';
export const USER_MEDIA_SHARED = 'USER_MEDIA_SHARED';

export const TOGGLE_MANUAL = 'TOGGLE_MANUAL';
export const TOGGLE_POP_UP_MANUAL = 'TOGGLE_POP_UP_MANUAL';

export const OPEN_MATERIAL_ASSIGNMENT = 'OPEN_MATERIAL_ASSIGNMENTS';
export const CLOSE_MATERIAL_ASSIGNMENT = 'CLOSE_MATERIAL_ASSIGNMENT';

export const TOGGLE_SOLUTIONS_PAGE = 'TOGGLE_SOLUTIONS_PAGE';

export const POP_UP_MANUAL_FETCH_SUCCESS = 'POP_UP_MANUAL_FETCH_SUCCESS';

export const ANNOTATION_SETS_FETCH_SUCCESS = 'ANNOTATION_SETS_FETCH_SUCCESS';
export const SET_ACTIVE_ANNOTATION_SET = 'SET_ACTIVE_ANNOTATION_SET';
export const SET_CREATE_ANNOTATION_SET_LOADING = 'SET_CREATE_ANNOTATION_SET_LOADING';
export const SHARED_SLIDE_SETS_FETCH_SUCCESS = 'SHARED_SLIDE_SETS_FETCH_SUCCESS';

export const CLOSE_POP_UP_NOTE = 'CLOSE_POP_UP_NOTE';
export const MOVE_POP_UP_NOTE_TO_FRONT = 'MOVE_POP_UP_NOTE_TO_FRONT';
