import get from 'get-value';
import ViewMode from '../enums/ViewMode';
import { getCurrentDigibook } from './digibooks';

export const getViewMode = state => get(state, ['navigation', 'viewMode']);
export const getIsMobileWidth = state => get(state, ['navigation', 'isMobileWidth']);
export const getCurrentPageNumber = state => get(state, ['navigation', 'currentPage']);
export const getClickedTocNode = state => get(state, ['navigation', 'clickedTocNode']);
export const getZoomLevel = state => get(state, ['navigation', 'zoomLevel']);
export const getZoomToFitMode = state => get(state, ['navigation', 'zoomToFitMode']);
export const getDisabledZoomType = state => get(state, ['navigation', 'disabledZoomType']);

export const canGotoNextPage = state => {
  const currentPage = getCurrentPageNumber(state);
  const digibook = getCurrentDigibook(state);
  const viewMode = getViewMode(state);

  if (digibook && digibook.totalPages) {
    if (digibook.backCover) {
      return currentPage <= digibook.totalPages;
    }
    if (viewMode === ViewMode.SPREAD && currentPage > 1) {
      return currentPage < digibook.totalPages - 1;
    }
    return currentPage < digibook.totalPages;
  }
  return false;
};

export const canGotoPreviousPage = state => {
  const currentPage = getCurrentPageNumber(state);
  const digibook = getCurrentDigibook(state);
  if (digibook && digibook.cover) {
    return currentPage > 0;
  }
  return currentPage > 1;
};
