import React from 'react';
import classNames from 'classnames';
import { string, node, func, number, oneOfType } from 'prop-types';
import DialogHeader from './header';

export default function GenericModal({ icon, title, children, close, footerContent, contentModifier, tabBar, zIndexOverlay, ...rest }) {
  return (
    <>
      <div className="ngdialog-overlay" style={{ zIndex: zIndexOverlay }} />
      <div {...rest} className="pbb-modal" style={{ zIndex: 999999999 }} role="presentation">
        <div className="pbb-modal__dialog pbb-modal--dialog-centered">
          <div className={classNames('pbb-modal__content', contentModifier)} data-testid="modal-content">
            <DialogHeader close={close} icon={icon} title={title} />
            <div className="pbb-modal__body">
              {tabBar}
              <div className="pbb-modal__body-wrapper">{children}</div>
            </div>
            {footerContent ? (
              <div className="pbb-modal__footer" style={{ position: 'initial' }}>
                {footerContent}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

GenericModal.propTypes = {
  children: node,
  title: string.isRequired,
  icon: oneOfType([string, node]),
  close: func.isRequired,
  footerContent: node,
  tabBar: node,
  contentModifier: string,
  zIndexOverlay: number,
};
