import React, { useContext } from 'react';
import classNames from 'classnames';

import { oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import AnchorPosition from '../../../../enums/anchorposition';
import { UserSettingsContext } from '../../context/user-settings-context';

export const SidebarToggle = ({ anchor }) => {
  const pbbSidebarButtonRoot = 'pbb-sidebar__switch';
  const pbbHamburgerRoot = 'pbb-hamburger';
  const isActive = currentAnchor => (currentAnchor === anchor ? 'is-active' : '');
  const [t] = useTranslation();
  const { sidebarAnchor, setSidebarAnchor, isSidebarOpen, setSidebarOpened } = useContext(UserSettingsContext);

  return (
    <div
      className={classNames(pbbSidebarButtonRoot, `${pbbSidebarButtonRoot}--${anchor}`)}
      data-testid="sidebar-toggle"
      style={sidebarAnchor === AnchorPosition.LEFT && anchor === 'right' ? { right: 'var(--scrollbar-width, 0px)' } : {}}
    >
      <button
        type="button"
        className={classNames(pbbHamburgerRoot, `${pbbHamburgerRoot}--htx`, isSidebarOpen && isActive(sidebarAnchor))}
        onClick={() => {
          if (isSidebarOpen && isActive(sidebarAnchor)) {
            setSidebarOpened(false);
          }
          setSidebarAnchor(anchor);
        }}
        data-testid="sidebar-toggle-button"
        title={isSidebarOpen && isActive(sidebarAnchor) ? t('drawer.buttons.sidebarClose.tooltip') : t('drawer.buttons.sidebarToggle.tooltip')}
      >
        <span>toggle menu</span>
      </button>
    </div>
  );
};

SidebarToggle.propTypes = {
  anchor: oneOf(Object.values(AnchorPosition)).isRequired,
};

export default React.memo(SidebarToggle);
